import { useDayjs } from '#dayjs'
import getEventSlug from '@/utils/getEventSlug'
import { jsxDEV } from 'vue/jsx-runtime'

type EventType = 'online_event' | 'in_person' | 'on_demand'

export interface EventDetails extends EventResponse {
  typeTag: string,
  eventType: EventType,
  dateLong: string
  dateShort: string,
  time: string,
  photo1: string | undefined,
  photo2: string | undefined,
  photo3: string | undefined,
  instructor: string | undefined,
  soldOut: boolean,
  instructorsString: string
  isExpired: boolean
  isPassedEventRecordingAvailable: boolean,
  slug: string
}

export function getTypeTag(event: EventResponse) {
  if (event.has_available_recordings) return 'On-Demand'
  if (event.event_type === 'studio_event') return 'In Person'

  return 'Online'
}

export function getEventType(event: EventResponse): EventType {
  if (event.has_available_recordings) return 'on_demand'
  if (event.event_type === 'studio_event') return 'in_person'

  return 'online_event'
}

export function getDateLong(date: string | Date) {
  const dayjs = useDayjs()

  return dayjs(date).format('MMMM D, YYYY [at] h:mmA')
}

export function getDateShort(date: string | Date) {
  const dayjs = useDayjs()

  return dayjs(date).format('MMM D, YYYY')
}

export function getTime(date: string | Date) {
  const dayjs = useDayjs()

  return dayjs(date).format('h:mm A')
}

export function getEventDetails(event: EventResponse): EventDetails {
  const photo1 = event.www_photo1?.image_url || event.cover_photo?.image_url
  const photo2 = event.www_photo2?.image_url || photo1
  const photo3 = event.www_photo3?.image_url || photo1

  const instructorsString = (event.instructors ?? []).map(instructor => instructor.name).join(', ')

  return {
    ...event,
    eventType: getEventType(event),
    typeTag: getTypeTag(event),
    dateLong: getDateLong(event.event_start_datetime),
    dateShort: getDateShort(event.event_start_datetime),
    time: getTime(event.event_start_datetime),
    photo1,
    photo2,
    photo3,
    instructor: (event.instructors ?? [])[0]?.name,
    soldOut: event.registration_required && event.registration_limit > 0 && event.registration_limit <= event.number_of_registrations,
    instructorsString,
    isExpired: new Date(event.event_end_datetime).getTime() < Date.now(),
    isPassedEventRecordingAvailable: new Date(event.event_start_datetime).getTime() < Date.now() && event.has_available_recordings,
    slug: getEventSlug(event.id, event.event_name)
  }
}

export function getAddToGoogleCalendarLink(event: EventDetails) {
  const dayjs = useDayjs()
  const eventName = event.event_name;
  const accessCodeParam = event.access_code
    ? `?access_code=${event.access_code}`
    : '';
  const eventLink = `${event.event_url}${accessCodeParam}`;

  // dates
  const dateFormat = 'YYYYMMDDTHHmmss';
  const start = dayjs(event.event_start_datetime).format(dateFormat);
  const end = dayjs(event.event_end_datetime).format(dateFormat);
  const eventEndDate = dayjs(event.event_end_datetime).format(dateFormat);

  // generate url
  const uri = new URL('https://www.google.com/calendar/render');
  const searchParams: any = {
    action: 'TEMPLATE',
    text: eventName,
    details: `<b>${eventName}</b>\n\n${eventLink}`,
    dates: start + '/' + end,
  };

  if (event.event_repeat_type === 'daily') {
    searchParams.recur = `RRULE:FREQ=DAILY;UNTIL=${eventEndDate};INTERVAL=${event.event_daily_repeat};`;
  } else if (event.event_repeat_type === 'weekly') {
    const byDayDays = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
    const byDay = event.event_weekly_repeat
      .split(',')
      .map(dayIndex => byDayDays[dayIndex])
      .join(',');

    searchParams.recur = `RRULE:FREQ=WEEKLY;UNTIL=${eventEndDate};BYDAY=${byDay};`;
  }

  Object.keys(searchParams).map(function (key) {
    uri.searchParams.append(key, searchParams[key]);
  });

  return uri.href;
}
