import { default as _91event_id_93EGkH0vdXXYMeta } from "/tmp/build_06865f2a/pages/events/[event_id].vue?macro=true";
import { default as experienceszEvkNgKifhMeta } from "/tmp/build_06865f2a/pages/experiences.vue?macro=true";
import { default as indexYY0dKo80KSMeta } from "/tmp/build_06865f2a/pages/index.vue?macro=true";
import { default as events_45slider_45example8qFXTiVjrlMeta } from "/tmp/build_06865f2a/pages/widgets/events-slider-example.vue?macro=true";
import { default as events_45sliderKbKIWnPsVVMeta } from "/tmp/build_06865f2a/pages/widgets/events-slider.vue?macro=true";
export default [
  {
    name: "events-event_id",
    path: "/events/:event_id()",
    component: () => import("/tmp/build_06865f2a/pages/events/[event_id].vue")
  },
  {
    name: "experiences",
    path: "/experiences",
    meta: experienceszEvkNgKifhMeta || {},
    component: () => import("/tmp/build_06865f2a/pages/experiences.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/tmp/build_06865f2a/pages/index.vue")
  },
  {
    name: "widgets-events-slider-example",
    path: "/widgets/events-slider-example",
    meta: events_45slider_45example8qFXTiVjrlMeta || {},
    component: () => import("/tmp/build_06865f2a/pages/widgets/events-slider-example.vue")
  },
  {
    name: "widget-events-slider",
    path: "/widgets/events-slider",
    meta: events_45sliderKbKIWnPsVVMeta || {},
    component: () => import("/tmp/build_06865f2a/pages/widgets/events-slider.vue")
  }
]